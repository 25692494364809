// Theme THX777

import { Roboto, Noto_Sans_Thai } from "next/font/google";
import { alpha, createTheme } from "@mui/material/styles";
import "../theme.d";

const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const notoSansThai = Noto_Sans_Thai({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const preset20 = createTheme({
  name: "preset25",
  config: {
    // แสดงปุ่มฝากถอนในกระเป๋าหรือไม่ ถ้าเป็น true จะแสดงปุ่ม ถ้าเป็น false จะไม่แสดงปุ่มในกระเป๋า
    // แต่จะแสดงนอกกระเป๋าเงิน
    showDepositAndWithdrawInWallet: false,
    // โลโก้
    logo: {
      // ขนาดโลโก้
      height: 100,
      // ขนาดโลโก้ใน AppBar
      appBarHeight: 80,
      // ระยะห่างของโลโก้ใน AppBar
      appBarSpacing: 10,
    },
  },
  palette: {
    mode: "dark",
    // สีเส้น
    divider: alpha("#e6c361", 0.4),
    primary: {
      // สีหลัก
      main: "#e6c361",
      // สีตัวอักษร
      contrastText: "#171203",
    },
    secondary: {
      // สีรอว
      main: "#e6c361",
      // สีตัวอักษร
      contrastText: "white",
    },
    background: {
      // สีพื้นหลัง
      default: "rgba(60,44,15,1)",
      // สี Card
      paper: "#0d1c07",
    },
  },
  backgroundImage: {
    // รูปพื้นหลัง
    body: "https://images-platform.sgp1.cdn.digitaloceanspaces.com/background/bg-gb%205.jpg",
    // ความทึบของรูปพื้นหลัง
    bodyOpacity: 0.5,
    bodySize: "auto 50%",
  },
  mineColor: {
    // สีพื้นหลังแบบสว่าง
    "paper-soft": "#172d0e",
    // สีพื้นหลังแบบมืด
    "paper-deep": "#1d4516",
  },
  shape: {
    // ขนาดขอบมน
    borderRadius: 16,
  },
  gradient: {
    // สีกระเป๋าตอนไม่ได้รับโบนัส
    100: "linear-gradient(197deg, rgba(230,195,97,1) 21%, rgba(184,151,59,1) 50%, rgba(157,125,37,1) 75%, rgba(184,151,59,1) 100%)",
    // สีกระเป๋าตอนรับโบนัส
    200: "linear-gradient(140deg, rgba(180,176,176,1) 0%, rgba(147,138,138,1) 40%, rgba(232,232,232,1) 100%)",
    // สี Card
    300: "linear-gradient(180deg, rgba(60,44,15,1) 0%, rgba(10,20,6,1) 30%, rgba(10,20,6,1) 90%, rgba(50,36,11,1) 100%)",
    // สี Appbar
    400: "linear-gradient(to right, #011914, #cead4e)",
  },
  wheel: {
    // ขอบกงล้อ
    borderColor: "#363228",
    segment: {
      // สีกงล้อ 1
      fillStyle1: "#e6c361",
      // สีกงล้อ 2
      fillStyle2: "#2e0d02",
    },
  },
  typography: {
    // ฟอนต์
    fontFamily: [notoSansThai.style.fontFamily, roboto.style.fontFamily].join(
      ","
    ),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: "10px",
          textTransform: "capitalize",
          ...(ownerState.variant === "contained" && {
            background: preset20.palette.primary.main,
          }),
          ...(ownerState.variant === "outlined" && {
            color: preset20.palette.primary.main,
            borderColor: preset20.palette.primary.main,
          }),
        }),
      },
    },
  },
});

export default preset20;
