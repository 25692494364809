// Theme Slow88

import { Roboto, Noto_Sans_Thai } from "next/font/google";
import { alpha, createTheme } from "@mui/material/styles";
import "../theme.d";

const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const notoSansThai = Noto_Sans_Thai({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const preset12 = createTheme({
  name: "preset12",
  config: {
    // แสดงปุ่มฝากถอนในกระเป๋าหรือไม่ ถ้าเป็น true จะแสดงปุ่ม ถ้าเป็น false จะไม่แสดงปุ่มในกระเป๋า
    // แต่จะแสดงนอกกระเป๋าเงิน
    showDepositAndWithdrawInWallet: false,
    // โลโก้
    logo: {
      // ขนาดโลโก้
      height: 100,
      // ขนาดโลโก้ใน AppBar
      appBarHeight: 100,
      // ระยะห่างของโลโก้ใน AppBar
      appBarSpacing: 7,
    },
  },
  palette: {
    mode: "dark",
    // สีเส้น
    divider: alpha("#9c27b0", 0.4),
    // สีหลัก (สีม่วงดำ)
    primary: {
      main: "#f9688d",
      contrastText: "#ffffff",
    },
    // สีลอง (ดำขาว)
    secondary: {
      main: "#f9688d",
      // main: "#ff0040",672e8a
      contrastText: "#000000",
    },
    background: {
      // สีพื้นหลัง
      default: "#ffd9eb",
      // สี Card
      paper: "#f9688d",
    },   
  },
  backgroundImage: {
    // รูปพื้นหลัง
    body: "xxx",
    // ความทึบของรูปพื้นหลัง
    bodyOpacity: undefined,
    bodySize: "contain",
  },
  mineColor: {
    // สีพื้นหลังแบบสว่าง
    "paper-soft": "#f9688d",
    // สีพื้นหลังแบบมืด
    "paper-deep": "#ffd9eb",
  },
  shape: {
    // ขนาดขอบมน
    borderRadius: 16,
  },
  gradient: {
    // สีกระเป๋าตอนไม่ได้รับโบนัส
    100: "#f9688d",
    // สีกระเป๋าตอนรับโบนัส
    200: "linear-gradient(330deg, rgba(160,198,22,1) 32%, rgba(127,158,12,1) 100%)",
    // สี Card
    300: "#E82E81",
    // สี Appbar
    400: "#E82E81",
    // สีปุ่ม Deposit
    500: "linear-gradient(0deg, #109179 0%, #53d2dc 100%)",
    // สีปุ่ม Withdraw
    600: "linear-gradient(360deg, #db991a 0%, #ffc05f 40%, #ffc05f 100%)",
    // สี Tag
    700: "#e82e81",
    // สี Logo FooterNav
    800: "#ffeffdb5",
    // สี Text ทั้งหมด
    900: "#ffffff",
    // สี Text 2
    910: "#f9de9b",
    // สี Text MainMenu & TagsFooter
    920: "#000000",
    // สี Bg myDeposit
    930: "#fff4e5"

  },
  wheel: {
    // ขอบกงล้อ
    borderColor: "#810136",
    segment: {
      // สีกงล้อ 1
      fillStyle1: "#ffa4b4",
      // สีกงล้อ 2
      fillStyle2: "#ba004d",
    },
  },
  typography: {
    // ฟอนต์
    fontFamily: [notoSansThai.style.fontFamily, roboto.style.fontFamily].join(
      ","
    ),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: "10px",
          textTransform: "capitalize",
          ...(ownerState.variant === "contained" && {
            background: preset12.palette.primary.main,
          }),
          ...(ownerState.variant === "outlined" && {
            color: "white",
            borderColor: preset12.palette.primary.main,
            "&:hover": {
              borderColor: "#b49ac7",
            },
          }),
        }),
      },
    },
  },
});

export default preset12;
