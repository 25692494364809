import { Roboto, Noto_Sans_Thai } from "next/font/google";
import { alpha, createTheme } from "@mui/material/styles";
import "../theme.d";

const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const notoSansThai = Noto_Sans_Thai({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const preset33 = createTheme({
  name: 'preset33',
  config: {
    // แสดงปุ่มฝากถอนในกระเป๋าหรือไม่ ถ้าเป็น true จะแสดงปุ่ม ถ้าเป็น false จะไม่แสดงปุ่มในกระเป๋า
    // แต่จะแสดงนอกกระเป๋าเงิน
    showDepositAndWithdrawInWallet: true,
    // โลโก้
    logo: {
      // ขนาดโลโก้
      height: 150,
      // ขนาดโลโก้ใน AppBar
      appBarHeight: 120,
      // ระยะห่างของโลโก้ใน AppBar
      appBarSpacing: 0,
    },
  },
  palette: {
    mode: "dark",
    // สีเส้น
    divider: alpha("#a966ff", 0.5),
    primary: {
      // สีหลัก
      // main: "#38101a",
      main: "#ffbf00",

      // สีตัวอักษร
      contrastText: "#white",
    },
    secondary: {
      // สีรอว
      main: "#290B12",
      // สีตัวอักษร
      contrastText: "621627",
    },
    background: {
      // สีพื้นหลัง
      default: "#621627 ",
      // สี Card
      paper: "#6e46a2",
    },
  },
  backgroundImage: {
    // รูปพื้นหลัง
    body: "/assets/bgs/bg_pastel.png",
    
    // ความทึบของรูปพื้นหลัง
    bodyOpacity: 0.1,
    bodySize: "cover",
    bodySizeMobile: "cover",
    backgroundAttachment: "fixed",
    backgroundPosition: "-190px -50px",
  },
 
  mineColor: {
    // สีพื้นหลังแบบสว่าง
    "paper-soft": "#ff9ddd",
    // สีพื้นหลังแบบมืด
    "paper-deep": "#c599ff ",
  },
  shape: {
    // ขนาดขอบมน
    borderRadius: 16,
  },
  gradient: {
    // สีกระเป๋าตอนไม่ได้รับโบนัส
    100: "linear-gradient(180deg, #ffbf00,#f8ca3f,#98bcd5,#66aad9)",
    // 100: "linear-gradient(180deg, rgba(190, 192, 194,1) 21%, rgba(161, 162, 163,1) 50%, rgba(112, 112, 111,1) 75%, rgba(161, 162, 163,1) 100%)",
    // สีกระเป๋าตอนรับโบนัส
    200: "linear-gradient(140deg, rgba(180,176,176,1) 0%, rgba(147,138,138,1) 40%, rgba(232,232,232,1) 100%)",
    // สี Card
    300: "linear-gradient(180deg, #ee35ae,#ff9ddd,#c599ff,#a966ff)",
    // สี Appbar
    // 400: "linear-gradient(90deg, rgba(60,44,15,1) 0%, rgba(10,20,6,1) 30%, rgba(10,20,6,1) 90%, rgba(50,36,11,1) 100%)",
    400: "linear-gradient(180deg, #ee35ae,#ff9ddd,#c599ff,#a966ff)",
    // 700:" #621627 ",
    700:" white ",
    // 920:" #ee35ae ",
    // 900:" white ",


  },
  wheel: {
    // ขอบกงล้อ
    borderColor: "#191712",
    segment: {
      // สีกงล้อ 1 
      fillStyle1: "#ff9ddd",
      // สีกงล้อ 2
      fillStyle2: "#c599ff",
    },
  },
  typography: {
    // ฟอนต์
    fontFamily: [notoSansThai.style.fontFamily, roboto.style.fontFamily].join(
      ","
    ),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: "10px",
          textTransform: "capitalize",
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              background: preset33.palette.primary.main,
            }),
          ...(ownerState.variant === "outlined" && {
            color: preset33.palette.primary.main,
            borderColor: preset33.palette.primary.main,
          }),
        }),
      },
    },
  },
});

export default preset33;
