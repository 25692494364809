"use client";

import { useRef } from "react";
import { useAuthStore } from "../authStore";

function AuthStore(props: any) {
  const initialized = useRef(false);
  if (!initialized.current) {
    useAuthStore.setState({ user: props.user });
    initialized.current = true;
  }
  return null;
}

export default AuthStore;
