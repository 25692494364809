"use client";

import * as React from "react";
import { SnackbarProvider as NotistackProvider, closeSnackbar } from "notistack";
import { Icon } from "@iconify/react";
import { Box } from "@mui/material";

type Props = {
  children: React.ReactNode;
};

export default function SnackbarProvider({ children }: Props) {
  return (
    <NotistackProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      action={(snackbarId) => (
        <Box
          onClick={() => closeSnackbar(snackbarId)}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Icon
            id="icon"
            icon={"material-symbols:close-small"}
            width={36}
            height={36}
          />
        </Box>
      )}
    >
      {children}
    </NotistackProvider>
  );
}
