import { Theme } from "@mui/material";

import defaultPreset from "./default";
import preset1 from "./preset1";
import preset2 from "./preset2";
import preset3 from "./preset3";
import preset4 from "./preset4";
import preset5 from "./preset5";
import preset6 from "./preset6";
import preset7 from "./preset7";
import preset8 from "./preset8";
import preset9 from "./preset9";
import preset10 from "./preset10";
import preset11 from "./preset11";
import preset12 from "./preset12";
import preset13 from "./preset13";
import preset14 from "./preset14";
import preset15 from "./preset15";
import preset16 from "./preset16";
import preset17 from "./preset17";
import preset18 from "./preset18";
import preset19 from "./preset19";
import preset20 from "./preset20";
import preset21 from "./preset21";
import preset22 from "./preset22";
import preset23 from "./preset23";
import preset24 from "./preset24";
import preset25 from "./preset25";
import preset26 from "./preset26";
import preset27 from "./preset27";
import preset28 from "./preset28";
import preset29 from "./preset29";
import preset30 from "./preset30";
import preset31 from "./preset31";
import preset32 from "./preset32";
import preset33 from "./preset33";


/**
 * รายชื่อและค่าของธีมที่ใช้ในแอปพลิเคชัน
 */
const themes: { [key: string]: Theme } = {
  default: defaultPreset,
  preset1, // THEGROUP168
  preset2, // THEGROUP999
  preset3, // THX777
  preset4, // DEMO666
  preset5, // SlOW88
  preset6, // LUCKYGIRL678
  preset7, // BET365TH
  preset8, // CARINO88
  preset9, // THEGROUPGAME369
  preset10, // THEGROUPLAO888
  preset11, // MANGGON999
  preset12, // PUPPYBET
  preset13, // PGGAME56
  preset14, // TIGA98
  preset15, // SIMPLE888
  preset16, // LEK77
  preset17, // ENJOYKRUB
  preset18, // FUNEE8
  preset19, // EHUB8
  preset20, // GAX88
  preset21, // V7HENG
  preset22, // MYWIN89
  preset23, // DAFU89
  preset24, // RVP8
  preset25, // GDP168
  preset26, // BCV789
  preset27, // KUBWIN
  preset28, // PG1PLAY
  preset29, // POKKAOBET
  preset30, // YEDPED123
  preset31, // DRAGONENG168
  preset32, // wisdom168
  preset33, // pgslotsoft

};

/**
 * ฟังก์ชัน `getTheme` ใช้สำหรับเรียกคืนธีมที่กำหนดไว้ในตัวแปร `NEXT_PUBLIC_THEME_PRESET` หากไม่พบค่าในตัวแปรดังกล่าว จะใช้ธีมเริ่มต้น "default" แทน
 * @returns ธีมที่กำหนดไว้ในตัวแปร `NEXT_PUBLIC_THEME_PRESET` หรือธีมเริ่มต้น "default"
 */
export const getTheme = () => {
  const presetName = process.env.NEXT_PUBLIC_THEME_PRESET || "default";
  return themes[presetName];
};
