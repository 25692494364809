import { Roboto, Noto_Sans_Thai } from "next/font/google";
import { alpha, createTheme } from "@mui/material/styles";
import "../theme.d";
import { palette } from "@mui/system";
import { ThemeContext } from "@emotion/react";

const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const notoSansThai = Noto_Sans_Thai({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const preset17 = createTheme({
  name: "preset17",
  config: {
    // แสดงปุ่มฝากถอนในกระเป๋าหรือไม่ ถ้าเป็น true จะแสดงปุ่ม ถ้าเป็น false จะไม่แสดงปุ่มในกระเป๋า
    // แต่จะแสดงนอกกระเป๋าเงิน
    showDepositAndWithdrawInWallet: false,
    // โลโก้
    logo: {
      // ขนาดโลโก้
      height: 140,
      // ขนาดโลโก้ใน AppBar
      appBarHeight: 130,
      // ระยะห่างของโลโก้ใน AppBar
      appBarSpacing: 0,
    },
  },
  palette: {
    mode: "dark",
    // สีเส้น
    divider: alpha("#9c27b0", 0.4),
    // สีหลัก (สีม่วงดำ)
    primary: {
      main: "#b06307",
      contrastText: "#FFFFFF",
    },
    // สีลอง (ดำขาว)
    secondary: {
      main: "#ff9933",
      contrastText: "#000000",
    },
    background: {
      // สีพื้นหลัง
      default: "#d87321",
      // สี Card
      paper: "#ff8f2e",
    },
  },
  backgroundImage: {
    // รูปพื้นหลัง
    body: undefined,
    // ความทึบของรูปพื้นหลัง
    bodyOpacity: undefined,
  },
  mineColor: {
    // สีพื้นหลังแบบสว่าง
    "paper-soft": "#ff9933",
    // สีพื้นหลังแบบมืด
    "paper-deep": "#ff9933",
  },
  shape: {
    // ขนาดขอบมน
    borderRadius: 16,
  },
  gradient: {
    // สีกระเป๋าตอนไม่ได้รับโบนัส
    100: "#312e2d",
    // สีกระเป๋าตอนรับโบนัส
    200: "linear-gradient(330deg, rgba(160,198,22,1) 32%, rgba(127,158,12,1) 100%)",
    // สี Card
    300: "#ff9933",
    // สี Appbar
    400: "linear-gradient(31deg, #ffeb2e, #ff8000)",
    // สีปุ่ม Deposit
    500: "linear-gradient(180deg, #ffcb52 0%, #ff7b02 100%)",
    // สีปุ่ม Withdraw
    600: "linear-gradient(1deg, #5c27fb 0%, #c165de 100%)",
    // สีปุ่ม GameMenu
    610: "linear-gradient(180deg, #fee2f2, #ffc590)",
    // สี Tag
    700: "#ffffff",    
    // สี Box&Text ถอน 1
    911: "#ffeaaa",    
    // สี Box&Text ถอน 2 & สี Text GameMenu
    912: "#9d4be9",
  },
  wheel: {
    // ขอบกงล้อ
    borderColor: "#FFCF96",
    segment: {
      // สีกงล้อ 1
      fillStyle1: "#F6F7D7",
      // สีกงล้อ 2
      fillStyle2: "#fb8211",
    },
  },
  typography: {
    // ฟอนต์
    fontFamily: [notoSansThai.style.fontFamily, roboto.style.fontFamily].join(
      ","
    ),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: "10px",
          textTransform: "capitalize",
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              background: preset17.palette.primary.main,
            }),
          ...(ownerState.variant === "outlined" && {
            color: preset17.palette.primary.main,
            borderColor: preset17.palette.primary.main,
          }),
        }),
      },
    },
  },
});

export default preset17;
