"use client";

import { useRef } from "react";
import { Config, useConfigStore } from "../configStore";

interface Props {
  config: Config | null;
  theme?: Object | {};
}

function ConfigStore({ config, theme }: Props) {
  const initialized = useRef(false);
  if (!initialized.current) {
    useConfigStore.setState({ config, theme });
    initialized.current = true;
  }
  return null;
}

export default ConfigStore;
