// Theme THX777

import { Roboto, Noto_Sans_Thai } from "next/font/google";
import { alpha, createTheme } from "@mui/material/styles";
import "../theme.d";

const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const notoSansThai = Noto_Sans_Thai({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const preset29 = createTheme({
  name: "preset29",
  config: {
    // แสดงปุ่มฝากถอนในกระเป๋าหรือไม่ ถ้าเป็น true จะแสดงปุ่ม ถ้าเป็น false จะไม่แสดงปุ่มในกระเป๋า
    // แต่จะแสดงนอกกระเป๋าเงิน
    showDepositAndWithdrawInWallet: false,
    // โลโก้
    logo: {
      // ขนาดโลโก้
      height: 100,
      // ขนาดโลโก้ใน AppBar
      appBarHeight: 100,
      // ระยะห่างของโลโก้ใน AppBar
      appBarSpacing: 5,
    },
  },
  palette: {
    mode: "dark",
    // สีเส้น
    divider: alpha("#000000", 0.4),
    primary: {
      // สีหลัก
      main: "#fff2ba",
      // สีตัวอักษร
      contrastText: "#000",
    },
    secondary: {
      // สีรอว
      main: "#a00000",
      // สีตัวอักษร
      contrastText: "white",
    },
    background: {
      // สีพื้นหลัง
      default: "#7b1818",
      // สี Card
      paper: "#90161a",
    },
  },
  backgroundImage: {
    // รูปพื้นหลัง
    body: undefined,
    // ความทึบของรูปพื้นหลัง
    bodyOpacity: undefined,
  },
  mineColor: {
    // สีพื้นหลังแบบสว่าง
    "paper-soft": "#600001",
    // สีพื้นหลังแบบมืด
    "paper-deep": "#7d5151",
  },
  shape: {
    // ขนาดขอบมน
    borderRadius: 16,
  },
  gradient: {
    // สีกระเป๋าตอนไม่ได้รับโบนัส
    100: "linear-gradient(0deg, rgb(115 10 10) 0%, #ff0000 100%)",
    // สีกระเป๋าตอนรับโบนัส
    200: "linear-gradient(344deg, rgba(195,34,49,1) 20%, rgba(253,187,45,1) 100%)",
    // สี Card
    300: "linear-gradient(0deg, #420000 0%, #ff0000 100%)",
    // สี Appbar
    400: "linear-gradient(0deg, #420000 0%, #ff0000 100%)",
    // สีปุ่ม Deposit
    500: "linear-gradient(140deg, #ffd4a0 0%, #b47831 40%, #fffbcc 100%)",
    // สีปุ่ม Withdraw
    600: "linear-gradient(344deg, rgb(202 39 39) 0%, #4b0305 40%, #c61917 100%)",
    // สีปุ่ม GameMenu
    610: "linear-gradient(181deg, #c18741 0%, #fffbcc 40%, #f0d998 100%)",
    // สี Tag
    700: "#f6e6ac",    
    // สี Logo FooterNav
    800: "#ffffff",
    // สี Text ขั้นตอนถอน
    911: "#f6e6ac",
    // สี Text MainMenu
    920: "#f6e6ac",
  },
  wheel: {
    // ขอบกงล้อ
    borderColor: "#000000",
    segment: {
      // สีกงล้อ 1
      fillStyle1: "#ffffff",
      // สีกงล้อ 2
      fillStyle2: "#000000",
    },
  },
  typography: {
    // ฟอนต์
    fontFamily: [notoSansThai.style.fontFamily, roboto.style.fontFamily].join(
      ","
    ),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: "10px",
          textTransform: "capitalize",
          ...(ownerState.variant === "contained" && {
            background: preset29.palette.primary.main,
          }),
          ...(ownerState.variant === "outlined" && {
            color: preset29.palette.primary.main,
            borderColor: preset29.palette.primary.main,
          }),
        }),
      },
    },
  },
});

export default preset29;
